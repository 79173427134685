/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@font-face {
  font-family: 'Codec Pro Extra Bold';
  src: local('Codec Pro Extra Bold'), url(../public/fonts/CodecPro-ExtraBold.woff) format('woff');
}
@font-face {
  font-family: 'Codec Pro Regular';
  src: local('Codec Pro Regular'), url(../public/fonts/codec-pro.regular.ttf) format('truetype');
}

body {
	line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Codec Pro Regular', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* reset over */

body {
  background: #101728!important;
}

button:hover {
  cursor: pointer;
}

p {
  line-height: 1.2;
}

.PageContainer {
  width: 100%!important;
  max-width: 100%!important;
  box-sizing: border-box!important;
  color: white;
  background:#101728;
}

.form-grid-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  box-sizing: border-box;
  grid-gap: 20px;
  padding: 20px;
  width: 75%;
  margin: 0 auto;
}

.grid-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  box-sizing: border-box;
}

.grid-element {
  height: 100vh;
}

.action-button {
  background: #f46524;
  color: white;
  font-family: 'Codec Pro Regular'!important;
  padding: 1rem;
  border-radius: 4px;
  border: none;
}

.padding-t-3 {
  padding-top: 3rem!important;
}

.padding-b-3 {
  padding-bottom: 3rem!important;
}

.m-0-auto {
  margin: 0 auto;
}

.mt-3 {
  margin-top: 3rem!important;
}

.mt-6 {
  margin-top: 6rem!important;
}

.mb-3 {
  margin-bottom: 3rem!important;
}

.mb-6 {
  margin-bottom: 3rem!important;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.p-5rem {
  padding: 5rem!important;
}

.flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 100%;
}

.flex-wrap {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
}

.flex-basis-50 {
  flex-basis: 50%;
  /* padding: 1rem; */
}

.align-self-flex-start {
  align-self: flex-start;
}

.flex-start-center-responsive {
  align-self: flex-start;
}

.stock-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

.text-center {
  text-align: center;
}

.center {
  text-align: center;
  margin: 0 auto;
  /* margin-left: auto; */
  justify-content: flex-end;
}

.w-65 {
  width: 65%;
}

.w-75 {
  width: 75%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%!important;
}


.icon-container {
  font-size: 2rem;
  margin-top: 10rem!important;
}

.row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}

.column {
  flex-grow: 1;
}

.display-block {
  display: block;
}

.undecorated-button {
  background: none;
  border: none;
}

.undecorated-button:hover {
  background: none;
  border: none;
}

.undecorated-link {
  color: white;
  text-decoration: none;
}

.undecorated-link:hover {
  color: #f46524;
}

.relative {
  position: relative;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fixed-bottm {
  position: sticky;
  right: 0;
  bottom: 0;
}

.text-orange {
  color: #f46524!important;
}

.text-ubuntu-title {
  font-family: 'Ubuntu'!important;
  color: #f46524;
  font-size: 2.5rem;
}

.text-large {
  font-family: "Codec Pro Extra Bold"!important;
  font-size: 4rem;
}

.text-medium {
  font-family: "Codec Pro Regular"!important;
  font-size: 2rem;
}

.text-small {
  font-family: "Codec Pro Regular"!important;
  font-size: 1.5rem;
}

.left-center-responsive {
  text-align: left;
}

.vh-100 {
  height: 100vh;
}

.span-2-desktop {
  grid-column: span 2;
}

@media only screen and (max-width: 1200px) {
  .span-2-desktop {
    grid-column: auto;
  }
  .form-grid-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .grid-container {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .grid-element {
    height: auto;
  }

  .flex-start-center-responsive {
    align-self: center;
  }

  .flex-center-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .mb-10-mobile {
    margin-bottom: 10rem;
  }

}

@media only screen and (max-width: 1000px) {
  .left-center-responsive {
    text-align: center;
  }
  .text-large {
    font-size: 3rem;
  }
  .text-medium {
    font-size: 1.8rem;
  }
}
