.IconButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  color: white;
}

.IconButton:hover {
  color: #f46524;
}