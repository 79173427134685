.ContactUsForm-container {
  text-align: center;
  margin: 0 auto;
  width: 50%;
}

.ContactUsForm-container input {
  margin-top: 3rem;
  height: 2rem;
}

.ContactUsForm-container textarea {
  margin-top: 3rem;
}
