.Navbar {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.305);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 0 5rem; */
}

.Navbar button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.Navbar-logo {
  width: 32px;
  margin: 0;
}

.Navbar-title {
  font-family: 'Ubuntu'!important;
  color: #f46524;
  font-size: 1.5rem;
  margin: 5px 0px 5px 5px;
}

.Navbar-text {
  font-family: 'Codec Pro Regular'!important;
  color: white;
  font-size: 1.2rem;
  margin: 10px 0px 2px 30px;
}
